import { render, staticRenderFns } from "./SearchLink.vue?vue&type=template&id=bdc4b00c&scoped=true"
import script from "./SearchLink.vue?vue&type=script&lang=js"
export * from "./SearchLink.vue?vue&type=script&lang=js"
import style0 from "./SearchLink.vue?vue&type=style&index=0&id=bdc4b00c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdc4b00c",
  null
  
)

export default component.exports