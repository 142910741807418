<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Search Links</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="addapplicationandservice">Add Link</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Search</label>
              <b-form-input  v-model="searchTerm" @keyup="search"></b-form-input>
            </b-col>
           
          </b-row>
           
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="12">          
              <b-table striped hover
                       sort-icon-left
                       :items="tableData.dataSource" 
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" 
                       @row-clicked="openApplicationAndService"
                       :per-page="tableData.resultsPerPage"
                       id="applicationandserviceSearch"
                       :current-page="tableData.currentPage">

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <template #cell(icon)="row">
                  <img width="50" :src="url + row.item.icon"/>
                </template>

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="openApplicationAndService(row.item)" size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
              <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="applicationandserviceSearch"
                ></b-pagination>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>
<style >
.primary{
background-color: #ffcb14;
color: #222222;
}
.primary:hover{
    color: #fff;
}
.page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
}
</style>
<script>

import { mapMutations} from "vuex";
import api from "../../../api";



export default {
  name: "applicationandserviceSearch",
  data: () => ({
    url: "",
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Grouping',
          key: 'grouping.name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Target',
          key: 'target',
          sortable: true,
          tdClass: '',
        },   
         {
          label: 'Url',
          key: 'url',
          sortable: true,
          tdClass: '',
        },   
        {
          label: 'List Order',
          key: 'listOrder',
          sortable: true,
          tdClass: '',
        },  
         {
          label: 'Main Menu',
          key: 'mainMenu',
          sortable: true,
          tdClass: '',
        }, 
         {
          label: 'Side Bar',
          key: 'sideBar',
          sortable: true,
          tdClass: '',
        }, {
          label: 'Icon',
          key: 'icon',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    links: [],
    searchTerm: '',
  
  }),
  beforeMount() {
   this.url = api.Url;
    this.setBreadcrumb([
      {
        text: 'Links'
      },
    ]);
     const s = (data) => {
      this.tableData.dataSource = data;
      this.links = data;
      console.log( data);
       this.tableData.isLoading = false;
   };

   const e = (msg) => {
     console.log(msg);
   };

    api.getAllLinks(s, e);
 
  },
  
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    search() {
      this.tableData.dataSource = this.links.filter((x) => 
        x.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || x.grouping.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || x.target.toLowerCase().includes(this.searchTerm.toLowerCase()) || x.url.toLowerCase().includes(this.searchTerm.toLowerCase())
      );  
    },
 
   openApplicationAndService(link) { 
      localStorage.setItem("view_link_id", link.id);
      this.$router.push({path: '/admin/ManageLinks/LinkView'})
    },
    
    addapplicationandservice() {
      this.$router.push({path: '/admin/ManageLinks/CreateLink'})
    }
  },
  computed: {
      rows() {
          return this.tableData.dataSource.length
      },
  },
}
</script>

<style scoped>

</style>